<template>
  <v-container style="height: 100%">
    <v-breadcrumbs :items="bcItems" large class="px-3"></v-breadcrumbs>
    <v-stepper alt-labels flat class="mb-3" v-model="step">
      <v-stepper-header>
        <template v-for="({ name }, i) in showRoutes">
          <v-stepper-step
            :key="`step_${name}`"
            editable
            :complete="i + 1 < activeStep"
            :step="i + 1"
          >
            {{ $t(name.toLowerCase()) }}
          </v-stepper-step>
          <v-divider
            :key="`divider_${name}`"
            v-if="i < showRoutes.length - 1"
          ></v-divider>
        </template>
      </v-stepper-header>
    </v-stepper>
    <router-view :record="record" :key="recordHash" />
    <v-fab-transition v-if="activeStep < showRoutes.length">
      <v-btn color="success" fixed dark bottom right fab @click="step++">
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </v-fab-transition>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { recordRoutes } from "@/router";

export default {
  name: "Record",
  data() {
    return { pinned: true, step: 1 };
  },
  computed: {
    ...mapState(["records"]),
    recordHash() {
      return btoa(unescape(encodeURIComponent(JSON.stringify(this.record))));
    },
    bcItems() {
      return [
        { text: this.$t("home"), to: "/" },
        {
          text: this.recordId,
          to: {
            name: "Datasheet",
            params: { recordId: this.$route.params.recordId }
          }
        },
        { text: this.$t(this.$route.matched[1].name.toLowerCase()) }
      ];
    },
    showRoutes() {
      return recordRoutes.filter(
        r =>
          this.$store.state.user &&
          r.meta.show.includes(this.$store.state.user.type)
      );
    },
    activeStep() {
      return this.showRoutes.map(r => r.name).indexOf(this.$route.name) + 1;
    },
    record() {
      if (!this.records) {
        this.fetchRecords({ PatientID: this.recordId });
        return null;
      }
      const r = this.records.Items.find(r => r.PatientID === this.recordId);
      if (!r && this.recordId) this.fetchRecords({ PatientID: this.recordId });
      return r;
    },
    recordId() {
      return this.$route.params.recordId;
    }
  },
  methods: {
    ...mapActions(["fetchRecords"])
  },
  watch: {
    async step(to, from) {
      if (to === this.activeStep) return;
      try {
        await this.$router.push({ name: this.showRoutes[to - 1].name });
      } catch (error) {
        console.log("navigation cancelled");
        this.step = from;
      }
    },
    activeStep(to) {
      this.step = to;
    }
  },
  mounted() {
    this.step = this.activeStep;
  }
};
</script>
